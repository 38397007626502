//changed

import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../components/Card";
import HeaderUnderlined from "../components/HeaderUnderlined";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const IndexPage = ({ data }) => {
  const { title, body, relationships, field_image } = data.allNodePage.nodes[0];
  const thumbnail = getImage(
    relationships.field_image.localFile.childImageSharp
  );
  const cardsData = relationships.field_service;
  return (
    <Layout>
      <Seo
        title={title}
        keywords={["web design", "gatsby", "personal web sites"]}
      />
      <Container fluid="lg">
        <Row>
          <Col
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ backgroundColor: "#17a2b857" }}
          >
            <div className="shadow-sm bg-secondary">
              <GatsbyImage image={thumbnail} alt={field_image.alt} />
            </div>
          </Col>
          <Col className="p-5 bg-white">
            <HeaderUnderlined titlesrc={title} className="fadein" />
            <div
              dangerouslySetInnerHTML={{
                __html: body.processed,
              }}
            />
          </Col>
        </Row>
      </Container>
      <div
        className="bg-primary-opas"
        style={{ backgroundBlendMode: "darken" }}
      >
        <Container fluid="lg" className="wavebackground">
          <Container fluid className="pt-5">
            {cardsData.map((cardData) => {
              return <Card cardData={cardData} key={cardData.id} />;
            })}
          </Container>
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allNodePage(filter: { path: { alias: { eq: "/home" } } }) {
      nodes {
        title
        body {
          processed
        }
        field_image {
          alt
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 70
                  width: 500
                  height: 500
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          field_service {
            id
            status
            title
            body {
              summary
            }
            path {
              alias
            }
            field_image {
              alt
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 70
                      width: 500
                      height: 500
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
